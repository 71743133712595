@tailwind base;
@tailwind components;
@tailwind utilities;

.lead-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .lead-item {
    flex-direction: row;
  }
}

.not-found {
  color: red;
  font-weight: bold;
}
